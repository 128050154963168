<template>
    <div class="iss-main-grid">

        <div class="iss-search-wrap">
            <search-form :items="items" @fnSearch="handleFnSearch" />
        </div>
        <!-- '2024:08:31 23:00:00'   allow-selection -->
        <div class="iss-grid-wrap mt-16">
            <grid
            ref="gridRef"
            :columns="columns"
            :code="$route.path"
            :url="url"
            :search="search"
            :pagination="false"
            :url-params="{ 
                beginTime: '2024-01-01 00:00:00',
                endTime: '2024-08-31 14:00:00',
            }"
            :btn-operation="btnOperation"
            :scroll="{ x: 1000, y: gridHeight }"
        >
        <template #operation="{ record }">
            <operation :options="options" :record="record" />
        </template>
          <template #avatar="{ record }">
              <a-avatar v-if="record.avatar" :src="setAvatar(record.avatar)" :size="30" />
              <a-avatar :size="30" v-else>
                <template #icon>
                   <UserOutlined />
                </template>
              </a-avatar>
          </template>

          <template #orderUserNum="{ record }">
            <div class="activeColor">{{record.orderUserNum}}人</div>
          </template>

          <template #userSource="{ record }">
            <div v-if="record.userSource === 0">否</div>
            <div v-else>是</div>
          </template>

          <template #status="{ record }">
            <div v-if="record.status === 1">注册</div>
            <div v-if="record.status === 0">注销</div>
          </template>

        </grid>
        </div>
     
    </div>
    <iss-edit 
      v-model:visible="visible"
      :init-value="activeItem"
      @fnOk="handleFnOkByEdit"
    />
  </template>
  
  <script>

import { reactive, toRefs, ref, onMounted } from "vue";
import { Avatar, message } from 'ant-design-vue';
import SearchForm from '@/components/searchForm';
import Grid from '@/components/grid';
import userApi from '@/api/user.js';
import Operation from '@/components/operation';
import {
  UserOutlined,
} from '@ant-design/icons-vue';
import issEdit from './components/edit';
import { setAvatar } from '@/utils';

  export default {

    components: {
        Grid,
        SearchForm,
        Operation,
        AAvatar: Avatar,
        UserOutlined,
        issEdit,

    },
    setup() {
      const gridRef = ref();
      const state = reactive({
        search: {},
        visible: false,
        activeItem: {},
        endTime: '',
  
      });

    const formatTimestamp = () =>{
    let date = new Date();
    let year = date.getFullYear();
    let month = ("0" + (date.getMonth() + 1)).slice(-2); // getMonth() is zero-based
    let day = ("0" + date.getDate()).slice(-2);
    let hours = ("0" + date.getHours()).slice(-2);
    let minutes = ("0" + date.getMinutes()).slice(-2);
    let seconds = ("0" + date.getSeconds()).slice(-2);

    //  ${minutes >= 10 ? minutes : '0' + minutes}:${seconds >= 10 ? seconds : '0' + seconds}
     state.endTime = `${year}-${month}-${day} ${
            hours >= 10 ? hours : '0' + hours
          }:${minutes}:${seconds}`;

            console.log('time',  state.endTime )
  };

      onMounted(()=>{
        // let timestamp = Date.now(); // 当前时间戳
        // let formattedDate = formatTimestamp(timestamp);
        // console.log(formattedDate);
        formatTimestamp()

    })
  
  
      return {
        ...toRefs(state),
        setAvatar,
        items: [
          {
            key: 'condition',
            label: '昵称、手机号',
            type: 'input',
          },
        {
          key: 'userSource',
          label: '是否是真实用户',
          type: 'select',
          dataset: [
            { label: '是', value: 1 },
            { label: '否', value: 0 },
          ],
        },
      ],
      columns: [
      { dataIndex: 'serialNumber', title: '排名', width: 50, ellipsis: true,
          slots: { customRender: 'serialNumber' },
        },
        { dataIndex: 'avatar', title: '头像', width: 60, ellipsis: true,
          slots: { customRender: 'avatar' },
        },
        { dataIndex: 'userName', title: '昵称', width: 120, ellipsis: true,
          slots: { customRender: 'userName' },
        },
        { dataIndex: 'goldNum', title: '金币数量', width: 80, ellipsis: true,
        },
        { dataIndex: 'userSource', title: '是否是真实用户', width: 80, ellipsis: true,
          slots: { customRender: 'userSource' },
        },
        { dataIndex: 'mobile', title: '手机号', width: 80, ellipsis: true,
          
        },
        {
          key: 'id',
          title: '操作',
          fixed: 'right',
          width: 80,
          slots: { customRender: 'operation' },
        },
      ],
      btnOperation: [
        {
          type: 'add',
          permission: 'resources:add',
          fnClick: () => {
            state.visible = true;
            state.activeItem = {};

          },
        },
      ],
      options: [
        {
          type: 'update',
          permission: 'resources:update',
          show: (record) => {
            if (record.userSource === 0) {
              return true
            } else {
              return false
            }
          },
          fnClick: record => {
            state.visible = true;
            Object.assign(state.activeItem , record);

          },
        },
        {
          type: 'delete',
          permission: 'resources:delete',
          show: (record) => {
            if (record.userSource === 0) {
              return true
            } else {
              return false
            }
          },
          fnClick: (record) =>{
            userApi.deleteById('resources:delete', record.userId ).then(() => {
              message.success('操作成功');
              gridRef.value.refreshGrid();
            })
          },
        },
      ],

      url: userApi.queryOrderUserList,
      gridRef,
      gridHeight: document.body.clientHeight - 260,
      handleFnSearch: value => {
        console.log('value搜索', value)
        const temp = Object.assign({}, value);
        // temp.tag = temp.tag?.join(',');
        state.search = temp;
      },
      handleFnOkByEdit: (value) => {
        state.visible = value
        gridRef.value.refreshGrid();

      },


      }
  
    },
  
  }
  </script>
  
  <style scoped lang="less">
  .iss-main-grid {
    padding: 0px 10px;
    max-height: calc(100vh - 88px);
    overflow-y: auto;

    }




  
  
  </style>